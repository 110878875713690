import {
  Box,
  Button,
  FileButton,
  Flex,
  Group,
  Image,
  Loader,
  NumberInput,
  ScrollArea,
  Select,
  Text,
  TextInput,
  Tooltip,
} from "@mantine/core";
import { IconRestore } from "@tabler/icons-react";
import { useEffect, useState } from "react";
import { FileToDataUrl } from "shared/utils/file";
import placeholder from "../../../../../../../assets/images/placeholder.png";
import { isEmail, useForm } from "@mantine/form";
import { useClubManagementContext } from "../../ClubManagement.context";
import toast from "react-hot-toast";
import { CountryList } from "shared/Constants/general.const";

const AddClubs = ({ close }) => {
  const [file, setFile] = useState(null);
  const [fileUrl, setFileUrl] = useState("");
  const { addClub, uploadImage, addClubLoading, setAddClubLoading } =
    useClubManagementContext();

  const form = useForm({
    initialValues: {
      clubName: "",
      clubLogo: "",
      clubFirstName: "",
      clubLastName: "",
      clubStreetAddress: "",
      clubZipCode: "",
      clubCity: "",
      clubCountry: "",
      clubPhone: "",
      clubEmail: "",
      clubVat: "",
    },
    validate: {
      clubName: (value) => (value ? null : "Please enter club name"),
      //   clubLogo: (value) => (value ? null : "Please upload club logo"),
      clubFirstName: (value) => (value ? null : "Please enter club first name"),
      clubLastName: (value) => (value ? null : "Please enter club last name"),
      clubStreetAddress: (value) =>
        value ? null : "Please enter club street address",
      clubZipCode: (value) => (value ? null : "Please enter club zip code"),
      clubCity: (value) => (value ? null : "Please enter club city"),
      clubCountry: (value) => (value ? null : "Please enter club country"),
      clubPhone: (value) => (value ? null : "Please enter club phone"),
      clubEmail: isEmail("Please enter club email"),
      clubVat: (value) => (value ? null : "Please enter club vat"),
    },
  });
  useEffect(() => {
    if (file) {
      FileToDataUrl(file).then((url) => {
        setFileUrl(url);
      });
    }
  }, [file]);

  const resetImageState = () => {
    setFile(null);
    setFileUrl("");
  };

  const handleSave = async () => {
    setAddClubLoading(true);
    const postData = {
      clubName: form.values.clubName,
      clubLogo: form.values.clubLogo,
      clubManagerId: null,
      clubContactFirstName: form.values.clubFirstName,
      clubContactLastName: form.values.clubLastName,
      clubStreetAddress: form.values.clubStreetAddress,
      clubPostcode: form.values.clubZipCode,
      clubCity: form.values.clubCity,
      clubCountry: form.values.clubCountry,
      clubPhone: form.values.clubPhone,
      clubEmail: form.values.clubEmail,
      clubVAT: form.values.clubVat,
    };

    if (file) {
      await uploadImage(file)
        .then(async (res) => {
          const logo = res?.uploadedFileUrls[0];
          postData.clubLogo = logo;
          await addClub(postData)
            .then(() => {
              toast("Save successful!", { appearance: "success" });
              close();
              setAddClubLoading(false);
            })
            .catch((error) => {
              toast(error.message || "Save failed. Please try again.", {
                appearance: "error",
              });
              setAddClubLoading(false);
            });
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
        });
    } else {
      await addClub(postData)
        .then(() => {
          toast("Save successful!", { appearance: "success" });
          close();
          setAddClubLoading(false);
        })
        .catch((error) => {
          toast(error.message || "Save failed. Please try again.", {
            appearance: "error",
          });
          setAddClubLoading(false);
        });
    }
  };

  return (
    <ScrollArea.Autosize mah={600}>
      {addClubLoading ? (
        <Flex justify={"center"} align={"center"} h={"70vh"}>
          <Loader size={"xl"} />
        </Flex>
      ) : (
        <Flex direction="column" gap="md">
          <Box
            component="form"
            onSubmit={form.onSubmit((values) => {
              if (form.isValid()) {
                handleSave();
              }
            })}
          >
            <TextInput
              withAsterisk
              label={<span style={{ fontWeight: "bold" }}>Club Name</span>}
              placeholder="Club Name"
              {...form.getInputProps("clubName")}
            />
            <Text fw="bold">Club Logo</Text>
            <Image
              radius="md"
              w={120}
              h={120}
              fit="contain"
              src={fileUrl || placeholder}
              className="m-auto"
            />
            <Group justify="center" mt={10}>
              <FileButton
                onChange={setFile}
                accept="image/png, image/jpeg, image/jpg"
              >
                {(props) => (
                  <Button {...props} color="gray">
                    Upload Logo
                  </Button>
                )}
              </FileButton>
              <Button variant="default" ml={8} onClick={resetImageState}>
                <Tooltip label="Reset Image">
                  <IconRestore />
                </Tooltip>
              </Button>
            </Group>
            <TextInput
              label={
                <span style={{ fontWeight: "bold" }}>
                  Club Contact First Name
                </span>
              }
              placeholder="Club Contact First Name"
              withAsterisk
              {...form.getInputProps("clubFirstName")}
            />
            <TextInput
              label={
                <span style={{ fontWeight: "bold" }}>
                  Club Contact Last Name
                </span>
              }
              placeholder="Club Contact Last Name"
              withAsterisk
              {...form.getInputProps("clubLastName")}
            />
            <TextInput
              label={
                <span style={{ fontWeight: "bold" }}>Club Street Address</span>
              }
              placeholder="Club Street Address"
              withAsterisk
              {...form.getInputProps("clubStreetAddress")}
            />
            <TextInput
              label={<span style={{ fontWeight: "bold" }}>Club PostCode</span>}
              withAsterisk
              placeholder="Club PostCode"
              {...form.getInputProps("clubZipCode")}
            />
            <TextInput
              label={<span style={{ fontWeight: "bold" }}>Club City</span>}
              withAsterisk
              placeholder="Club City"
              {...form.getInputProps("clubCity")}
            />
            <Select
              label={<span style={{ fontWeight: "bold" }}>Club Country</span>}
              withAsterisk
              placeholder="Club Country"
              clearable
              searchable
              data={CountryList}
              {...form.getInputProps("clubCountry")}
            />
            <NumberInput
              label={<span style={{ fontWeight: "bold" }}>Club Phone</span>}
              withAsterisk
              placeholder="Club Phone"
              hideControls
              {...form.getInputProps("clubPhone")}
            />
            <TextInput
              label={<span style={{ fontWeight: "bold" }}>Club Email</span>}
              withAsterisk
              placeholder="Club Email"
              {...form.getInputProps("clubEmail")}
            />
            <TextInput
              label={
                <span style={{ fontWeight: "bold" }}>Club VAT Number</span>
              }
              withAsterisk
              placeholder="Club VAT Number"
              {...form.getInputProps("clubVat")}
            />
            <Flex justify="space-between">
              <Button mt={8} onClick={close}>
                Cancel
              </Button>
              <Button type="submit" mt={8}>
                Save
              </Button>
            </Flex>
          </Box>
        </Flex>
      )}
    </ScrollArea.Autosize>
  );
};

export default AddClubs;
